<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import QRCode from "qrcode";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "",
      items: [],
      loading: true,
      messagelist: [],
      page: 1,
      userinfo: [],
      limit: 20,
      messagetotal: 0,
      cashier_config: [],
      cashier_status: false,
      goodstype: []
    };
  },
  mounted() {
    this.title = this.$t("menuitems.cashier.text");
    this.items = [
      {
        text: this.$t("menuitems.cashier.text"),
        active: true,
      },
    ];
    this.userinfo = JSON.parse(localStorage.getItem("user"));
    //   this.page.title=this.$t('global.notice.type.notify')
    this.get_cashier_config()
  },
  methods: {
    get_cashier_config(){
      var that = this;
      that.loading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "get_cashier_config"
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          if(response.data.status == 200){
            that.cashier_status = true
            that.cashier_config = response.data.config
            that.get_goodstype(that.cashier_config.type)
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    get_goodstype(route){
      var that = this;
      that.loading = true
      that.$axios
        .post(
          that.apiuri,
          {
            action: "get_" + route + "_catlogs",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false
          that.goodstype = response.data.data.foodCategory;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    save_cashier_config(){
      var that = this;
      that.loading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "save_cashier_config",
            config: that.cashier_config
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          that.$message({
            message: that.$t("global.success"),
            type: "success",
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="loading" v-if="cashier_status">
            <el-form ref="form" label-width="80px" v-if="cashier_config.type=='hualala'">
              <el-form-item label="启用状态">
                <el-switch
                  v-model="cashier_config.status"
                  active-text="启用"
                  inactive-text="禁用">
                </el-switch>
              </el-form-item>
              <el-form-item label="集团ID">
                <el-input v-model="cashier_config.group_id"></el-input>
              </el-form-item>
              <el-form-item label="店铺ID">
                <el-input v-model="cashier_config.shopid"></el-input>
              </el-form-item>
              <el-form-item label="商品分类">
                <el-select v-model="cashier_config.foodCategoryId" placeholder="请选择">
                  <el-option
                    v-for="item in goodstype"
                    :key="item.foodCategoryID"
                    :label="item.foodCategoryName"
                    :value="item.foodCategoryID">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="save_cashier_config">保存</el-button>
              </el-form-item>
              <el-form-item>
                
              </el-form-item>
            </el-form>
            <el-form ref="form" label-width="80px" v-else-if="cashier_config.type=='yzg'">
              <el-form-item label="启用状态">
                <el-switch
                  v-model="cashier_config.status"
                  active-text="启用"
                  inactive-text="禁用">
                </el-switch>
              </el-form-item>
              <el-form-item label="店铺ID">
                <el-input v-model="cashier_config.bar_id"></el-input>
              </el-form-item>
              <el-form-item label="证书指纹">
                <el-input v-model="cashier_config.fingerprint"></el-input>
              </el-form-item>
              <el-form-item label="证书文件">
                <el-input type="textarea" v-model="cashier_config.certpem"></el-input>
              </el-form-item>
              <el-form-item label="API地址">
                <el-input type="textarea" v-model="cashier_config.uri"></el-input>
              </el-form-item>
              <el-form-item label="API路径">
                <el-input type="textarea" v-model="cashier_config.path"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="save_cashier_config">保存</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class="card-body" v-else>
            <el-empty description="当前功能未开通"></el-empty>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>